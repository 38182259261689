const Inter = [
  {
    space_src: "baidujs",
    space_type: "inters",
    space_id: "u6943062",
    space_js: "//gflpjby.gnpad.cn/common/idcl/z/static/vj/source/c/s.js"
  }
];

const Native = [
  {
    space_src: "baidujs",
    space_type: "native",
    space_id: "u6943063",
    space_js: "//gflpjby.gnpad.cn/production/j/production/edmaw/static/k/resource/d/w.js"
  }
];

const Banner = [
  {
    space_src: "baidujs",
    space_type: "native",
    space_id: "u6943064",
    space_js: "//gflpjby.gnpad.cn/common/k/resource/f/e_nbxl_eb.js"
  }
];

export default {
  Home_inter: Inter,
  Home_native: Native,
  Home_banner: Banner,
  Detail_inter: Inter,
  Detail_native: Native,
  Detail_banner: Banner,
  List_native: Native,
  List_banner: Banner,
  Result_inter: Inter,
  Result_native: Native,
  Result_banner: Banner
};
